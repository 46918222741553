import Alert from '@material-ui/core/Alert'
// import Button from '@material-ui/core/Button'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

const ErrorAlert = ({ error, resetErrorBoundary }) => {
  if (error.message.startsWith(`false`)) {
    return null
  }

  return (
    <Alert
      severity="error"
      /*action={
        <Button color="inherit" size="small" onClick={resetErrorBoundary}>
          Try again
        </Button>
      }*/
    >
      {error.message}
    </Alert>
  )
}

export const AppBoundary = ({ children }) => (
  <ErrorBoundary
    FallbackComponent={ErrorAlert}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
    {children}
  </ErrorBoundary>
)
