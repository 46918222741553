import firebase from 'firebase/app'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  FirebaseAppProvider,
  preloadAuth,
  preloadFirestore,
  preloadFunctions,
  // preloadStorage,
} from 'reactfire'
import { App } from './App'
import { register as registerServiceWorker } from './service-worker-registration'

const AUTH_DOMAIN = 'wwww.fyi'
const FUNCTIONS_REGION = 'europe-west2'

fetch('/__/firebase/init.json').then(async (response) => {
  const firebaseConfig = await response.json()

  if (process.env.NODE_ENV === 'production') {
    firebaseConfig.authDomain = AUTH_DOMAIN
  }

  const firebaseApp = firebase.initializeApp(firebaseConfig)

  await preloadAuth({
    firebaseApp,
    setup: async (factory) => {
      const auth = factory()

      if (process.env.NODE_ENV !== 'production') {
        auth.useEmulator('http://localhost:9099')
      }

      return auth
    },
  })

  await preloadFirestore({
    firebaseApp,
    setup: async (factory) => {
      const firestore = factory()

      if (process.env.NODE_ENV !== 'production') {
        firestore.useEmulator('localhost', 8080)
      }

      // await firestore.clearPersistence()

      if (process.env.NODE_ENV === 'production') {
        await firestore.enablePersistence({ synchronizeTabs: true })
      }

      return firestore
    },
  })

  await preloadFunctions({
    firebaseApp,
    setup: (factory) => {
      const functions = factory(FUNCTIONS_REGION)

      if (process.env.NODE_ENV !== 'production') {
        functions.useEmulator('localhost', 5001)
      }

      return functions
    },
  })

  // await preloadStorage({
  //   firebaseApp,
  //   setup: async (factory) => {
  //     const storage = factory()
  //
  //     if (process.env.NODE_ENV !== 'production') {
  //       storage.useEmulator('http://localhost:9199')
  //     }
  //
  //     return storage
  //   },
  // })

  ReactDOM.unstable_createRoot(document.getElementById('root')).render(
    <FirebaseAppProvider firebaseApp={firebaseApp} suspense={true}>
      <App />
    </FirebaseAppProvider>
  )
})

registerServiceWorker()
