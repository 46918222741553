import LinearProgress from '@material-ui/core/LinearProgress'
import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthCheck } from 'reactfire'
import './App.css'
import { AppBoundary } from './AppBoundary'
import { Authenticate, AuthenticationHandler } from './Authenticate'
import { Pages } from './Pages'
import { Theme } from './Theme'

export const App = () => (
  <Theme>
    <AppBoundary>
      <Suspense fallback={<LinearProgress />}>
        <AuthCheck fallback={<Authenticate />}>
          <AuthenticationHandler />
          <BrowserRouter>
            <Suspense fallback={<LinearProgress />}>
              <Pages />
            </Suspense>
          </BrowserRouter>
        </AuthCheck>
      </Suspense>
    </AppBoundary>
  </Theme>
)
