import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ProjectsProvider } from './ProjectsProvider'

const ProjectPage = React.lazy(() => import('./ProjectPage'))
const ProjectsPage = React.lazy(() => import('./ProjectsPage'))

export const Pages = () => (
  <ProjectsProvider>
    <Routes>
      <Route path="/" element={<ProjectsPage />} />
      <Route path="/:id" element={<ProjectPage />} />
    </Routes>
  </ProjectsProvider>
)
