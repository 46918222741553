import React, { createContext, useContext } from 'react'
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire'

const ProjectsContext = createContext()

export const useProjects = () => {
  const value = useContext(ProjectsContext)

  if (value === undefined) {
    throw new Error(
      'Projects context is only available inside ProjectsProvider'
    )
  }

  return value
}

export const ProjectsProvider = ({ children }) => {
  const { data: user } = useUser()
  const db = useFirestore()
  const projectsRef = db.collection('projects')

  // const path = new useFirestore.FieldPath('roles', user.uid, 'expires')
  // projectsRef.where(path, '<', Date.now()),

  const { data: oldProjects } = useFirestoreCollectionData(
    projectsRef.where('uid', '==', user.uid),
    { idField: 'id' }
  )

  const { data: newProjects } = useFirestoreCollectionData(
    projectsRef.where('roles.readers', 'array-contains', user.uid),
    { idField: 'id' }
  )

  const projectsMap = new Map()

  for (const project of [...newProjects, ...oldProjects]) {
    if (!projectsMap.has(project.id)) {
      projectsMap.set(project.id, project)
    }
  }

  const projects = [...projectsMap.values()]

  return (
    <ProjectsContext.Provider value={{ projects, projectsRef }}>
      {children}
    </ProjectsContext.Provider>
  )
}
