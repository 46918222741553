import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Bookmark from '@material-ui/icons/Bookmark'
import Note from '@material-ui/icons/Note'
import Share from '@material-ui/icons/Share'
import React, { useEffect } from 'react'
import { useAuth, useFirebaseApp } from 'reactfire'

export const Authenticate = React.memo(() => {
  const auth = useAuth()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      {/*<div
        style={{
          fontSize: 20,
          letterSpacing: -2,
          textShadow: '0 1px 1px #000',
        }}
      >
        <span style={{ color: 'rgba(255, 100, 100, 1)' }}>W</span>
        <span style={{ color: 'rgba(255, 255, 100, 1)' }}>W</span>
        <span style={{ color: 'rgba(100, 255, 100, 1)' }}>W</span>
        <span style={{ color: 'rgba(100, 100, 255, 1)' }}>W</span>
      </div>*/}

      <div style={{ fontSize: 20 }}>WWWW</div>

      <List style={{ margin: '20px 8px' }}>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <Bookmark />
          </ListItemIcon>
          <ListItemText
            primary={'Collect sources while researching a topic'}
            secondary={`Each source is saved to the Internet Archive's Wayback Machine`}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <Note />
          </ListItemIcon>
          <ListItemText
            primary={'Add notes and comments to a source'}
            secondary={'Entities are automatically extracted from each note'}
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <Share />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                Share with readers{' '}
                <small style={{ color: '#666' }}>(coming soon)</small>
              </>
            }
            secondary={"Publish the content of a space when you're ready to share"}
          />
        </ListItem>
      </List>

      <div>
        <Button
          color="primary"
          variant={'contained'}
          onClick={
            () =>
              auth
                .signInWithPopup(
                  new auth.app.firebase_.auth.GoogleAuthProvider()
                )
                .then(() => window.location.reload()) // TODO: remove this
          }
        >
          Sign in with Google
        </Button>
      </div>
    </div>
  )
})

export const AuthenticationHandler = () => {
  const auth = useAuth()
  const app = useFirebaseApp()
  // const functions = useFunctions()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const functions = app.functions('europe-west2')

      const updateProfile = functions.httpsCallable('updateProfile')

      return auth.onAuthStateChanged((user) => {
        if (user) {
          updateProfile().catch((error) => {
            console.error(error)
          })
        }
      })
    }
  }, [auth, app])

  return null
}
